import React, { useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import { Booth, DesignElement } from 'models/cms/booths';
import BusinessPopover from 'pages/booths/components/BusinessPopover';
import { useSelector } from 'react-redux';
import { selectLocale } from 'models/localization';
import { BoothContext } from 'pages/booths/BoothProfileContainer';
import BoothTemplate04Table from 'assets/booths/booth4/table.png';
import findAvailableLocale from 'models/helpers/findAvailableLocale';
import ScreenBackground from './BoothBackground';
import TanShelf from './BrochureShelf';
import BoothContainer from '../../../components/BoothContainer';
import GenericCMSBoothBanner from '../../../components/GenericCMSBoothBanner';

const BoothWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(1.3);
  top: 102px;
`;

const TopBannerContainer = styled.div<{ enableClick: boolean }>`
  position: absolute;
  width: 276px;
  height: 75px;
  top: 2px;
  left: calc((100% - 248px) / 2);
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const TopBannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowDeskContainer = styled.div`
  position: absolute;
  top: 121px;
  width: 224px;
  height: 111px;
  left: calc((100% - 194px) / 2);
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const VerticalBannerContainer = styled.div`
  height: 262px;
  width: 119px;
  position: absolute;
  top: 102.5px;
`;

const LeftBannerContainer = styled(VerticalBannerContainer)`
  left: 95px;
`;

const RightBannerContainer = styled(VerticalBannerContainer)`
  top: 105px;
  right: 66px;
`;

const BusinessPopoverContainer = styled.div`
  position: absolute;
  top: -20px;
  right: -40px;
  width: 340px;
  transform: scale(0.77);
`;

const BrochureContainer = styled.div<{ enableClick: boolean }>`
  position: absolute;
  bottom: 111px;
  right: -38px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const SaleContainer = styled.div<{ backgroundSrc: string }>`
  background: url(${({ backgroundSrc }) => backgroundSrc});
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 120px;
  height: 280px;
  left: 240px;
  bottom: 200px;
`;

const CenterBannerContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const BottomContainer = styled.div`
  position: absolute;
  top: 293px;
  width: 312px;
  height: 83px;
  left: calc((100% - 283px) / 2);
`;

const TableContainer = styled.div`
  background: url(${BoothTemplate04Table});
  position: absolute;
  width: 528px;
  left: -106px;
  top: -33px;
  height: 185px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export interface BannerProps {
  booth: Booth;
  handleClick: (type: string) => void;
  isPreview?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  booth,
  handleClick = () => null,
  isPreview = false,
}: BannerProps) => {
  const locale = useSelector(selectLocale);
  const [showBannerModal, setShowBannerModal] = useState(false);
  const backgroundColor: string = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'background_color')?.color, [booth.designElements]);
  const bottomColor: string = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'bottom_color')?.color, [booth.designElements]);
  const leftBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'left_banner'), [booth.designElements]);
  const rightBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'right_banner'), [booth.designElements]);
  const topBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'top_banner'), [booth.designElements]);
  const centerBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode.name === 'middle_banner'), [booth.designElements]);
  const bottomBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode.name === 'bottom_banner'), [booth.designElements]);
  const representative: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode.name === 'representative'), [booth.designElements]);
  const representativeImg: string | null = useMemo(() => (findAvailableLocale(representative.image, locale) || {}).originalImageUrl, [locale, representative.image]);

  const tabs = booth?.rootContentElement?.tabs || [];
  const emailContacts = useMemo(
    () => tabs
      .flatMap((it) => it.body)
      // eslint-disable-next-line no-underscore-dangle
      .filter((it) => it.__typename === 'BoothQuestionContentElement' && it?.answer?.contentRichtextHtml?.[locale])
      .map((it) => ({
        type: 'email',
        contact: it.answer.contentRichtextHtml[locale].match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)?.[0],
      }))
      .filter((it) => it.contact),
    [tabs, locale],
  );
  const boothForMatomo = useContext(BoothContext);

  const onBannerClick = (key) => {
    window.trackingEvent('Booth', key, `${boothForMatomo.id} - ${boothForMatomo.boothNameForMatomo}`);
    switch (key) {
      case 'Banner 2_Click':
        if (booth.website) {
          window.open(`http://${booth.website.replace(/\b(https:\/\/|http:\/\/)/, '')}`, '_blank');
        }
        break;
      default:
        break;
    }
  };

  return (
    <BoothContainer needOffset={!isPreview}>
      <BoothWrapper>
        <BackgroundContainer>
          <ScreenBackground
            boothColor={backgroundColor || bottomColor || '#CF2F2F'}
            // boothColorRight={rightColor || leftColor || '#CF2F2F'}
          />
        </BackgroundContainer>
        {
          topBanner && (
            <TopBannerContainer enableClick={!!booth.website} onClick={() => onBannerClick('Banner 2_Click')}>
              <TopBannerWrapper>
                <GenericCMSBoothBanner
                  bannerData={topBanner}
                />
              </TopBannerWrapper>
            </TopBannerContainer>
          )
        }
        {
          centerBanner && (
            <ShowDeskContainer onClick={() => onBannerClick('Banner 3_Click')}>
              <CenterBannerContainer>
                <GenericCMSBoothBanner
                  onBannerClick={handleClick as any}
                  autoPlayVideo={!isPreview}
                  bannerData={centerBanner}
                />
              </CenterBannerContainer>
            </ShowDeskContainer>
          )
        }
        {
          representative && (
            <SaleContainer backgroundSrc={representativeImg} />
          )
        }
        {
          bottomBanner && (
            <BottomContainer onClick={() => onBannerClick('Banner 5_Click')}>
              <TableContainer />
              <CenterBannerContainer>
                <GenericCMSBoothBanner
                  onBannerClick={handleClick as any}
                  autoPlayVideo={!isPreview}
                  bannerData={bottomBanner}
                />
              </CenterBannerContainer>
            </BottomContainer>
          )
        }
        {
          leftBanner && (
            <LeftBannerContainer onClick={() => onBannerClick('Banner 1_Click')}>
              <GenericCMSBoothBanner
                bannerData={leftBanner}
              />
            </LeftBannerContainer>
          )
        }
        {
          rightBanner && (
            <RightBannerContainer onClick={() => onBannerClick('Banner 6_Click')}>
              <GenericCMSBoothBanner
                bannerData={rightBanner}
              />
            </RightBannerContainer>
          )
        }
        <BusinessPopoverContainer>
          <BusinessPopover
            boothId={booth.id}
            boothName={booth.name[locale]}
            emailContacts={emailContacts}
            meetupUrl={booth.meetupEnabled ? booth.appointmentBookingUrl : ''}
            livestreams={booth.livestreams}
            videoCallUrl={booth.videoCallUrl}
          />
        </BusinessPopoverContainer>
        <BrochureContainer enableClick={booth.hasAttachment} onClick={() => handleClick('attachment')}>
          <TanShelf hasAttachment={booth.hasAttachment} />
        </BrochureContainer>
        {
          showBannerModal && (
            <Lightbox
              mainSrc={null}
              onCloseRequest={() => setShowBannerModal(false)}
            />
          )
        }
      </BoothWrapper>
    </BoothContainer>
  );
};

export default Banner;
