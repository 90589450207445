import React, { useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';

import { Booth } from 'models/cms/booths';
import { BoothContext } from 'pages/booths/BoothProfileContainer';
import BoothContainer from '../../components/BoothContainer';
import BoothChair from '../../components/BoothChair';
import WhiteShelf from '../../components/brochureShelf/WhiteShelf';
import GenericCMSBoothBanner from '../../components/GenericCMSBoothBanner';
import BoothSaleContainer from './BoothSaleContainer';
import LogoDesk from '../../components/desk/LogoDesk';
import LightBackground618 from '../../components/background/LightBackground618';

const ChairContainer = styled.div`
  position: absolute;
  width: 160px;
  height: 200px;
  bottom: -90px;
  left: -150px;
`;

const TopBannerContainer = styled.div`
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 368px;
  height: 215px;
  top: 119px;
  left: 281px;
`;

const TopBannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ShowDeskContainer = styled.div<{ enableClick: boolean }>`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 370px;
  width: 470px;
  height: 280px;
  left: calc((100% - 400px) / 2);
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 20px;
  top: -50px;
`;

const LeftBannerContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/577/content/poster-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 201.5px;
  padding: 6.4px 9px 9px;
  position: absolute;
  bottom: -50px;
  left: 40px;
`;

const BrochureContainer = styled.div<{ enableClick: boolean }>`
  position: absolute;
  bottom: -50px;
  right: 0;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  bottom: -100px;
`;

const LeftSaleContainer = styled(SaleContainer)`
  right: auto;
  left: 0;
`;

const CenterBannerContainer = styled.div`
  height: 206px;
  top: 7px;
  position: relative;
`;

export interface BannerProps {
  booth: Booth;
  handleClick: (type: string) => void;
  isPreview?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  booth,
  handleClick = () => null,
  isPreview = false,
}: BannerProps) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const showRightRepresentative = useMemo(() => !isPreview && !!(booth.designElements || []).find((designNode) => designNode.name === 'right_representative'), [isPreview, booth.designElements]);
  const showLeftRepresentative = useMemo(() => !isPreview && !!(booth.designElements || []).find((designNode) => designNode.name === 'left_representative'), [isPreview, booth.designElements]);
  const boothForMatomo = useContext(BoothContext);

  const {
    leftColor, rightColor, tableColor, chairColor,
    leftBanner, topBanner, centerBanner,
  } = useMemo(() => {
    const elements = (booth.designElements || []);
    return ({
      leftColor: elements.find((designNode) => designNode.name === 'left_color')?.color,
      rightColor: elements.find((designNode) => designNode.name === 'right_color')?.color,
      tableColor: elements.find((designNode) => designNode.name === 'table_color')?.color,
      chairColor: elements.find((designNode) => designNode.name === 'chair_color')?.color,
      leftBanner: elements.find((designNode) => designNode.name === 'left_banner'),
      topBanner: elements.find((designNode) => designNode.name === 'top_banner'),
      centerBanner: elements.find((designNode) => designNode.name === 'middle_banner'),
    });
  }, [booth.designElements]);

  const onBannerClick = (key) => {
    window.trackingEvent('Booth', key, `${boothForMatomo.id} - ${boothForMatomo.boothNameForMatomo}`);
    switch (key) {
      case 'Banner 3_Click':
        if (booth.website) {
          window.open(booth.website, '_blank');
        }
        break;
      default:
        break;
    }
  };

  return (
    <BoothContainer needOffset={!isPreview}>
      <BackgroundContainer>
        <LightBackground618
          boothColor={leftColor || rightColor || '#CF2F2F'}
          boothColorRight={rightColor || leftColor || '#CF2F2F'}
        />
      </BackgroundContainer>
      <TopBannerContainer onClick={() => onBannerClick('Banner 2_Click')}>
        <TopBannerWrapper>
          <GenericCMSBoothBanner
            isDescriptionBanner
            onBannerClick={handleClick as any}
            autoPlayVideo={!isPreview}
            bannerData={topBanner}
          />
        </TopBannerWrapper>
      </TopBannerContainer>
      <ShowDeskContainer enableClick={!!booth.website} onClick={() => onBannerClick('Banner 3_Click')}>
        <CenterBannerContainer>
          <LogoDesk
            deskColor={tableColor || chairColor || leftColor || rightColor || '#CF2F2F'}
            logo={centerBanner}
          />
        </CenterBannerContainer>
      </ShowDeskContainer>
      <LeftBannerContainer onClick={() => onBannerClick('Banner 1_Click')}>
        <GenericCMSBoothBanner
          bannerData={leftBanner}
        />
      </LeftBannerContainer>
      <BrochureContainer enableClick={booth.hasAttachment} onClick={() => handleClick('attachment')}>
        <WhiteShelf hasAttachment={booth.hasAttachment} />
      </BrochureContainer>
      <ChairContainer>
        <BoothChair color={chairColor || tableColor || leftColor || rightColor || '#CF2F2F'} />
      </ChairContainer>
      {
        showRightRepresentative && (
          <SaleContainer id="reps">
            <BoothSaleContainer booth={booth} sale="male-right" />
          </SaleContainer>
        )
      }
      {
        showBannerModal && (
          <Lightbox
            mainSrc={null}
            onCloseRequest={() => setShowBannerModal(false)}
          />
        )
      }
      {
        showLeftRepresentative && (
          <LeftSaleContainer id="reps">
            <BoothSaleContainer booth={booth} sale="male-left" />
          </LeftSaleContainer>
        )
      }
    </BoothContainer>
  );
};

export default Banner;
