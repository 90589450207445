import React, {
  useContext, useEffect, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { useSelector, useDispatch, batch } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import Apps from 'apps';
import {
  APP, ENV,
  API_URL,
  RONGCLOUD_APP_KEY,
  RONGCLOUD_CHATROOM_PREFIX,
  VIDEO_CALL_URL,
  ENABLED_FEATURES,
} from 'appenv';
import { setConversations, updateConversation, addConversation } from 'models/conversations';
import {
  addMessage, setMessages, removeMessage, receiveMessage,
  receiveCallInvitation, receiveCallAccept, receiveCallHangup, receiveCallSummaryMessage, receiveCallJoinApprove,
} from 'models/messages';
import groupChatJoin from 'network/groupChatJoin';
import createVideoCallRoom from 'network/createVideoCallRoom';
import quitVideoCallRoom from 'network/quitVideoCallRoom';
import acceptVideoCallInvitation from 'network/acceptVideoCallInvitation';
import createGroupVideoCallRoom from 'network/createGroupVideoCallRoom';
import { setTarget } from 'models/chatTarget';
import { fetchRongcloudGroupMemberList } from 'models/chatTargetGroupMembers';
import { selectExhibitorIds } from 'models/exhibitors';
import { selectBoothIds } from 'models/cms/booths';
import { setCallInfo } from 'models/rtcCallingInfo';
import { setCallStatus } from 'models/rtcCallingStatus';
import { selectLocale } from 'models/localization';
import { EventDatetimeContext } from 'components/EventDatetime';
import ChatWindowSwitch711 from 'components/chat/chat_window_711/ChatWindowSwitch711';
import isChatroomDisabled from 'components/chat/isChatroomDisabled';
import ChatContext from './chat/ChatContext';
import ChatWindowSwitch from './chat/ChatWindowSwitch';
import RtcChatBackdrop from './chat/RtcChatBackdrop';
import isChatroomDisabledByReed from './chat/utils/isChatroomDisabledByReed';
import { UserSessionContext } from './UserSession';

const TwilioVideoMessageType = ['VideoCallInvite', 'VideoCallHangup', 'VideoCallAccept', 'VideoCallJoinRequest', 'VideoCallJoinApprove'];

const uploadLink = createUploadLink({
  uri: API_URL,
  headers: {
    'keep-alive': 'true',
  },
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: uploadLink,
});

const disableAnnouncement = ['hkstp608'];

const isEnableBlockOtherExhibitors = [Apps.Eventage824, Apps.Reed902].includes(APP);

const selectors = ENABLED_FEATURES.xtraCMS
  ? { selectExhibitorIds: selectBoothIds }
  : { selectExhibitorIds };

const RongCloudChat = ({ children }) => {
  const { t } = useTranslation();
  const { userSession } = useContext(UserSessionContext);
  const { targetId, type } = useSelector((state) => state.chatTarget);
  const loginTime = useSelector((state) => state.loginTime);
  const [status, setStatus] = useState('');
  const [isChatWindowShow, setIsChatWindowShow] = useState(false);
  const [joiningChat, setJoiningChat] = useState(false);
  const dispatch = useDispatch();
  const [hasMoreMsg, setHasMoreMsg] = useState(false);
  const [showGroupMemberList, setShowGroupMemberList] = useState(false);
  const [chatRoomTitle, setChatRoomTitle] = useState('Chatroom');
  const boothIds = useSelector(selectors.selectExhibitorIds);
  const { hasEventStarted } = useContext(EventDatetimeContext);
  const [rtcAlert, setRtcAlert] = useState('');
  const rtcCallingStatus = useSelector((state) => state.rtcCallingStatus);
  const callStatusRef = useRef();
  callStatusRef.current = rtcCallingStatus;
  const rtcCallingInfo = useSelector((state) => state.rtcCallingInfo);
  const callInfoRef = useRef();
  callInfoRef.current = rtcCallingInfo;
  const locale = useSelector(selectLocale);

  const checkIfLanding = () => window.location.pathname.startsWith('/landing');
  const shouldChatWindowDisplay = () => (
    !checkIfLanding()
    && hasEventStarted
    && !isEmpty(boothIds)
    && !isEmpty(userSession)
    && !['ms624', Apps.Jetro817].includes(APP)
    && !isChatroomDisabledByReed()
    && !isChatroomDisabled()
  );

  const getConversationType = (targetType) => {
    let conversationType = '';
    switch (targetType) {
      case 'group':
        conversationType = window.RongIMLib.ConversationType.GROUP;
        break;
      case 'private':
        conversationType = window.RongIMLib.ConversationType.PRIVATE;
        break;
      case 'system':
        conversationType = window.RongIMLib.ConversationType.SYSTEM;
        break;
      default:
        break;
    }
    return conversationType;
  };

  const sendMessage = (receiverId, conversationType, toSendmessage, messageType) => {
    if (receiverId && toSendmessage && conversationType) {
      const {
        name,
        email,
        rongcloudUserId,
        customAttendeeFields: {
          vexpo_chat_tag: tag,
          vexpo_chat_user_description: description,
        },
      } = userSession;
      const user = {
        name,
        email,
        rongcloudUserId,
        tag,
        description,
      };
      let msg;
      switch (messageType) {
        case 'FileMessage':
          msg = new window.RongIMLib.FileMessage({
            name: toSendmessage.name,
            size: toSendmessage.size,
            type: toSendmessage.type,
            fileUrl: toSendmessage.fileUrl,
            user,
          });
          break;
        case 'AudioCallMessage':
          msg = new window.RongIMClient.RegisterMessage.AudioCallMessage({
            content: toSendmessage,
            messageName: 'AudioCallMessage',
            user,
          });
          break;
        case 'VideoCallMessage':
          msg = new window.RongIMClient.RegisterMessage.VideoCallMessage({
            content: toSendmessage,
            messageName: 'VideoCallMessage',
            user,
          });
          break;
        case 'VideoCallInvite':
          msg = new window.RongIMClient.RegisterMessage.VideoCallInvite({
            content: toSendmessage,
            messageName: 'VideoCallInvite',
            user,
          });
          break;
        case 'VideoCallHangup':
          msg = new window.RongIMClient.RegisterMessage.VideoCallHangup({
            content: toSendmessage,
            messageName: 'VideoCallHangup',
            user,
          });
          break;
        case 'VideoCallAccept':
          msg = new window.RongIMClient.RegisterMessage.VideoCallAccept({
            content: toSendmessage,
            messageName: 'VideoCallAccept',
            user,
          });
          break;
        case 'ContactCardMessage':
          msg = new window.RongIMClient.RegisterMessage.ContactCardMessage({
            content: toSendmessage,
            messageName: 'ContactCardMessage',
            user,
          });
          break;
        case 'VideoCallJoinRequest':
          msg = new window.RongIMClient.RegisterMessage.VideoCallJoinRequest({
            content: toSendmessage,
            messageName: 'VideoCallJoinRequest',
            user,
          });
          break;
        case 'VideoCallJoinApprove':
          msg = new window.RongIMClient.RegisterMessage.VideoCallJoinApprove({
            content: toSendmessage,
            messageName: 'VideoCallJoinApprove',
            user,
          });
          break;
        default:
          msg = new window.RongIMLib.TextMessage({
            content: toSendmessage,
            user,
          });
      }

      window.RongIMClient.getInstance().sendMessage(conversationType, receiverId, msg, {
        onSuccess: (message) => {
          message.id = message.messageUId || message.messageId;
          const messageStr = JSON.stringify(message);
          const messageJSON = JSON.parse(messageStr);
          if (!TwilioVideoMessageType.includes(message.messageType)) {
            batch(() => {
              dispatch(addMessage(messageJSON));
              dispatch(updateConversation({
                id: receiverId,
                changes: {
                  latestMessage: messageJSON,
                },
              }));
            });
          }
        },
        onError: (errorCode) => {
          const failedMsg = new window.RongIMLib.TextMessage({
            content: {
              content: toSendmessage,
              user: {
                name,
                tag,
                description,
              },
            },
            sentTime: moment().valueOf(),
            sentFailed: errorCode,
            messageId: moment().valueOf(),
            receiverId,
            senderUserId: userSession.rongcloudUserId,
          });
          const messageStr = JSON.stringify(failedMsg);
          const messageJSON = JSON.parse(messageStr);
          batch(() => {
            dispatch(addMessage(messageJSON));
            dispatch(updateConversation({
              id: receiverId,
              changes: {
                latestMessage: messageJSON,
              },
            }));
          });
        },
      });
      dispatch(updateConversation({
        id: receiverId,
        changes: {
          sentTime: moment().valueOf(),
        },
      }));
    }
  };

  const getHistoryMsg = useCallback(() => {
    if (targetId) {
      const conversationType = getConversationType(type);
      const count = 20;
      window.RongIMClient.getInstance().getHistoryMessages(conversationType, targetId, null, count, {
        onSuccess: (list, hasMsg) => {
          if (list.length > 0) {
            const messageList = list.filter((message) => !TwilioVideoMessageType.includes(message.messageType));
            const listJSON = messageList.map((message) => {
              message.id = message.messageUId || message.messageId;
              const messageStr = JSON.stringify(message);
              const messageJSON = JSON.parse(messageStr);
              return messageJSON;
            });
            if (listJSON.length > 0) {
              dispatch(setMessages(listJSON));
            } else {
              getHistoryMsg();
            }
          }
          setHasMoreMsg(hasMsg);
        },
        onError: (error) => {
          console.log('Failed to get history messages', error);
        },
      });
    }
  }, [dispatch, type, targetId]);

  const connect = () => {
    const { rongcloudToken } = (userSession || {});
    if (!rongcloudToken) return;
    window.RongIMClient.connect(rongcloudToken, {
      onSuccess: () => {
        setStatus('connected');
        window.RongIMClient.getInstance().getConversationList({
          onSuccess: (list) => {
            batch(() => {
              if (!isEmpty(list)) {
                const privateIds = [];
                const conversationList = list.map((conversation) => {
                  const messageStr = JSON.stringify(conversation.latestMessage);
                  const messageJSON = JSON.parse(messageStr);
                  let conversationType = '';
                  switch (conversation.conversationType) {
                    case 3:
                      conversationType = 'group';
                      break;
                    case 1:
                      conversationType = 'private';
                      break;
                    case 6:
                      conversationType = 'system';
                      break;
                    default:
                      break;
                  }
                  const newObject = {
                    id: conversation.targetId,
                    unreadMessageCount: conversation.unreadMessageCount,
                    latestMessage: messageJSON,
                    conversationType,
                    sentTime: conversation.sentTime,
                  };
                  if (conversation.conversationType === 1) {
                    privateIds.push(conversation.targetId);
                  }
                  return newObject;
                });
                dispatch(setConversations({
                  list: conversationList,
                  ids: privateIds,
                }));
              }
              if (!disableAnnouncement.includes(APP)) {
                dispatch(addConversation({
                  id: `${RONGCLOUD_CHATROOM_PREFIX}_announcement`,
                  type: 'system',
                }));
              }
            });
          },
          onError: (error) => console.log('fail to fetch conversation list', error),
        }, null, 200);
      },
      onError: (error) => console.log('fail to connect rongcloud im', error),
    });
  };

  const disconnect = () => {
    try {
      window.RongIMClient.getInstance().disconnect();
    } catch (e) {
      console.error(e);
    }
  };

  const resetCallInfo = () => {
    batch(() => {
      dispatch(setCallStatus('available'));
      dispatch(setCallInfo({}));
    });
  };

  const hangup = async (chatId, callInvitationType, roomToken) => {
    if (chatId && callInvitationType && roomToken) {
      const { rongcloudUserId } = userSession;
      const { caller } = callInfoRef.current;
      const { success } = await quitVideoCallRoom(rongcloudUserId, roomToken);
      if (success) {
        sendMessage(chatId, callInvitationType, roomToken, 'VideoCallHangup');
      }
      if (caller === rongcloudUserId) {
        sendMessage(chatId, callInvitationType, t('call.cancelled'), 'VideoCallMessage');
      }
      resetCallInfo();
    }
  };

  const createVideoCall = async (roomName, chatId, inviteeIds, inviteeType) => {
    const { rongcloudUserId } = userSession;
    batch(() => {
      dispatch(setCallStatus('waiting_for_response'));
      dispatch(setCallInfo({
        name: roomName,
        caller: rongcloudUserId,
      }));
    });
    const { success, videoCallRoom: { token }, errors } = await createVideoCallRoom(rongcloudUserId, inviteeIds);
    window.trackingEvent('VideoCall', 'Video_Call_Click', userSession.email);
    if (success) {
      batch(() => {
        dispatch(setCallInfo({
          name: roomName,
          caller: rongcloudUserId,
          roomToken: token,
          conversationType: inviteeType,
          chatId,
          calleeIds: inviteeIds,
        }));
      });
      sendMessage(chatId, inviteeType, token, 'VideoCallInvite');
      setTimeout(() => {
        if (callStatusRef.current && callInfoRef.current && callStatusRef.current === 'waiting_for_response' && callInfoRef.current.roomToken === token) {
          hangup(chatId, inviteeType, token);
          setRtcAlert(t('call.no_answer'));
        }
      }, 60000);
    } else {
      resetCallInfo();
      setRtcAlert(t(`call.${errors[0].type}`));
    }
  };

  const createGroupVideoCall = async (roomName, boothId) => {
    const { rongcloudUserId } = userSession;
    batch(() => {
      dispatch(setCallStatus('waiting_for_response'));
      dispatch(setCallInfo({
        name: roomName,
        caller: rongcloudUserId,
      }));
    });
    const {
      success, videoCallRoom, roomAdmin, errors,
    } = await createGroupVideoCallRoom(boothId, rongcloudUserId);
    const { token, status: roomStatus } = videoCallRoom || {};
    const { uuid } = roomAdmin || {};
    if (success) {
      const conversationType = window.RongIMLib.ConversationType.PRIVATE;
      batch(() => {
        dispatch(setCallInfo({
          name: roomName,
          caller: rongcloudUserId,
          roomAdmin: uuid,
          roomToken: token,
          conversationType,
          chatId: uuid,
        }));
      });
      if (roomStatus === 'pending') {
        sendMessage(uuid, conversationType, token, 'VideoCallInvite');
      } else if (roomStatus === 'in_progress') {
        sendMessage(uuid, conversationType, token, 'VideoCallJoinRequest');
      }
      setTimeout(() => {
        if (callStatusRef.current && callInfoRef.current && callStatusRef.current === 'waiting_for_response' && callInfoRef.current.roomToken === token) {
          hangup(uuid, conversationType, token);
          setRtcAlert(t('call.no_answer'));
        }
      }, 60000);
    } else {
      resetCallInfo();
      setRtcAlert(t(`call.${errors[0].type}`));
    }
  };

  const accept = async (chatId, callInvitationType, roomToken) => {
    if (chatId && callInvitationType && roomToken) {
      const { rongcloudUserId } = (userSession || {});
      dispatch(setCallStatus('callee_connecting'));
      const { success, errors } = await acceptVideoCallInvitation(rongcloudUserId, roomToken);
      if (success) {
        window.trackingEvent('VideoCall', 'VideoCall_ConnectedPage_Enter', userSession.email);
        sendMessage(chatId, callInvitationType, roomToken, 'VideoCallAccept');
        const videoCallUrl = `${VIDEO_CALL_URL}?roomToken=${roomToken}&participantId=${rongcloudUserId}&locale=${locale}`;
        window.open(videoCallUrl, '_blank');
      } else {
        setRtcAlert(t(`call.${errors[0].type}`));
        resetCallInfo();
      }
    }
  };

  const registerMessageType = (messageName, objectName) => {
    const isCounted = true;
    const isPersited = true;
    const mesasgeTag = new window.RongIMLib.MessageTag(isCounted, isPersited);
    const prototypes = ['content', 'messageName', 'user'];
    window.RongIMClient.registerMessageType(messageName, objectName, mesasgeTag, prototypes);
  };

  const resendMessage = (message) => {
    dispatch(removeMessage(message.id));
    sendMessage(message.targetId, message.conversationType, message.content.content, message.messageType);
  };

  async function joinGroupChat(groupId) {
    setJoiningChat(true);
    const { authToken } = userSession;
    const success = await groupChatJoin(authToken, groupId);
    if (success) {
      setJoiningChat(false);
      dispatch(fetchRongcloudGroupMemberList({ groupId }));
    }
  }

  const setChatTarget = (id, targetType, title) => {
    setShowGroupMemberList(false);
    batch(() => {
      dispatch(setTarget({ targetId: id, type: targetType, roomName: title }));
      if (id && targetType) {
        setChatRoomTitle(title || id);
        if (targetType === 'group') {
          joinGroupChat(id);
        }
        dispatch(updateConversation({
          id,
          changes: {
            unreadMessageCount: 0,
          },
        }));
        dispatch(addConversation({
          id,
          type: targetType,
        }));
        const conversationType = getConversationType(targetType);
        window.RongIMClient.getInstance().clearUnreadCount(conversationType, id, {
          onSuccess: () => { },
          onError: () => { },
        });
      }
    });
  };

  const closeChatWindow = () => {
    setChatTarget(null, null);
    setIsChatWindowShow(false);
    setHasMoreMsg(false);
  };

  const showChatWindow = (
    id,
    chatType,
    title,
    isPublicChatroom = false,
  ) => {
    if (isEmpty(userSession)) return;
    const {
      role: userRole,
      customAttendeeFields: {
        vexpo_exhibitor_booth_id: exhibitorBoothID,
      },
    } = userSession;
    const exhibitorChatroomID = `${RONGCLOUD_CHATROOM_PREFIX}_booth_${exhibitorBoothID}`;
    const isExhibitorDenied = (isEnableBlockOtherExhibitors && chatType === 'group' && userRole === 'exhibitor' && !isEmpty(id) && id !== exhibitorChatroomID && !isPublicChatroom);
    if (isExhibitorDenied) {
      // eslint-disable-next-line no-alert
      alert(t(
        'chat.no_permission',
        'You have no permission to access other booths.',
      ));
    } else {
      setIsChatWindowShow(true);
      setChatTarget(id, chatType, title);
    }
  };

  const initialize = () => {
    if (!isEmpty(userSession) && APP !== 'ms624' && shouldChatWindowDisplay()) {
      window.RongIMClient.init(RONGCLOUD_APP_KEY);
      window.RongIMLib.RongIMEmoji.init();
      const { rongcloudUserId } = userSession;
      window.RongIMClient.setConnectionStatusListener({
        onChanged: (currentStatus) => {
          switch (currentStatus) {
            case window.RongIMLib.ConnectionStatus.CONNECTED:
              setStatus('connected');
              break;
            case window.RongIMLib.ConnectionStatus.CONNECTING:
              setStatus('connecting');
              break;
            case window.RongIMLib.ConnectionStatus.DISCONNECTED:
              setStatus('disconnected');
              break;
            case window.RongIMLib.ConnectionStatus.KICKED_OFFLINE_BY_OTHER_CLIENT:
              // eslint-disable-next-line no-alert
              alert(t(
                'call.logged_in_other',
                'Your account has been logged-in from other tab/browser. You must refresh the current browser to use the Chat function.',
              ));
              setStatus('error');
              break;
            case window.RongIMLib.ConnectionStatus.DOMAIN_INCORRECT:
              setStatus('error');
              break;
            case window.RongIMLib.ConnectionStatus.NETWORK_UNAVAILABLE:
              setStatus('offline');
              connect();
              break;
            default:
              break;
          }
        },
      });

      window.RongIMClient.setOnReceiveMessageListener({
        onReceived: (message) => {
          if (message.sentTime > loginTime) {
            message.id = message.messageUId || message.messageId;
            const messageStr = JSON.stringify(message);
            const messageJSON = JSON.parse(messageStr);
            switch (message.messageType) {
              case window.RongIMClient.MessageType.TextMessage:
              case window.RongIMClient.MessageType.FileMessage:
              case window.RongIMClient.RegisterMessage.ContactCardMessage:
                dispatch(receiveMessage(messageJSON));
                break;
              case window.RongIMClient.MessageType.VideoCallMessage:
                dispatch(receiveCallSummaryMessage({
                  message: messageJSON,
                  rongcloudUserId,
                }));
                break;
              case window.RongIMClient.MessageType.VideoCallInvite:
                dispatch(receiveCallInvitation({
                  message: messageJSON,
                  rongcloudUserId,
                  setRtcAlert,
                }));
                break;
              case window.RongIMClient.MessageType.VideoCallHangup:
                dispatch(receiveCallHangup({
                  message: messageJSON,
                  rongcloudUserId,
                  sendMessage,
                }));
                break;
              case window.RongIMClient.MessageType.VideoCallAccept:
                dispatch(receiveCallAccept({
                  message: messageJSON,
                  rongcloudUserId,
                  sendMessage,
                }));
                break;
              case window.RongIMClient.MessageType.VideoCallJoinApprove:
                dispatch(receiveCallJoinApprove({
                  message: messageJSON,
                  rongcloudUserId,
                  setRtcAlert,
                }));
                break;
              case window.RongIMClient.MessageType.UnknownMessage:
                if (message.objectName === 's:webinar:alert') {
                  dispatch(receiveMessage(messageJSON));
                }
                break;
              case window.RongIMClient.MessageType.ImageMessage:
              case window.RongIMClient.MessageType.HQVoiceMessage:
              case window.RongIMClient.MessageType.RichContentMessage:
              default:
                break;
            }
            if (message.conversationType === getConversationType('system') && !isChatWindowShow) {
              showChatWindow(`${RONGCLOUD_CHATROOM_PREFIX}_announcement`, 'system', 'Announcement');
            }
          }
        },
      });
      registerMessageType('VideoCallMessage', 's:video');
      registerMessageType('VideoCallInvite', 's:video:invite');
      registerMessageType('VideoCallHangup', 's:video:hangup');
      registerMessageType('VideoCallAccept', 's:video:accept');
      registerMessageType('ContactCardMessage', 's:contact');
      registerMessageType('WebinarAlertMessage', 's:webinar:alert');
      registerMessageType('VideoCallJoinRequest', 's:video:join');
      registerMessageType('VideoCallJoinApprove', 's:video:approve');
      connect();
    }
  };

  useEffect(() => {
    if (targetId && type && !joiningChat) {
      getHistoryMsg();
    }
  }, [type, targetId, joiningChat, getHistoryMsg]);

  useEffect(() => {
    if (!isEmpty(boothIds) && status !== 'connected') {
      initialize();
    }
  }, [boothIds]);

  useEffect(() => {
    if (callStatusRef.current && callInfoRef.current && callStatusRef.current === 'being_called') {
      const token = callInfoRef.current.roomToken;
      setTimeout(() => {
        const { chatId, conversationType, roomToken } = callInfoRef.current;
        if (roomToken === token && callStatusRef.current === 'being_called') {
          hangup(chatId, conversationType, roomToken);
        }
      }, 60000);
    }
  }, [rtcCallingStatus, rtcCallingInfo]);

  return (
    <ChatContext.Provider
      value={{
        targetId,
        type,
        showChatWindow,
        closeChatWindow,
        disconnect,
        chatroomAvailable: shouldChatWindowDisplay(),
        rtcAlert,
        setRtcAlert,
        createVideoCall,
        createGroupVideoCall,
        sendMessage,
        status,
      }}
    >
      {children}
      {
        shouldChatWindowDisplay() && (
          <ApolloProvider client={client}>
            {
              [Apps.SCMP711, Apps.Reed902].includes(APP) ? (
                <ChatWindowSwitch711
                  status={status}
                  joiningChat={joiningChat}
                  sendMessage={sendMessage}
                  resendMessage={resendMessage}
                  getHistoryMsg={getHistoryMsg}
                  chatRoomTitle={chatRoomTitle}
                  hasMoreMsg={hasMoreMsg}
                  setChatTarget={setChatTarget}
                  isChatWindowShow={isChatWindowShow}
                  setIsChatWindowShow={setIsChatWindowShow}
                  setChatRoomTitle={setChatRoomTitle}
                  showGroupMemberList={showGroupMemberList}
                  setShowGroupMemberList={setShowGroupMemberList}
                />
              ) : (
                <ChatWindowSwitch
                  status={status}
                  joiningChat={joiningChat}
                  sendMessage={sendMessage}
                  resendMessage={resendMessage}
                  getHistoryMsg={getHistoryMsg}
                  chatRoomTitle={chatRoomTitle}
                  hasMoreMsg={hasMoreMsg}
                  setChatTarget={setChatTarget}
                  isChatWindowShow={isChatWindowShow}
                  setIsChatWindowShow={setIsChatWindowShow}
                  setChatRoomTitle={setChatRoomTitle}
                  showGroupMemberList={showGroupMemberList}
                  setShowGroupMemberList={setShowGroupMemberList}
                />
              )
            }
          </ApolloProvider>
        )
      }
      {
        shouldChatWindowDisplay() && (ENABLED_FEATURES.personalVideoCall || ENABLED_FEATURES.groupVideoCall) && (
          <RtcChatBackdrop
            accept={accept}
            hangup={hangup}
          />
        )
      }
    </ChatContext.Provider>
  );
};

RongCloudChat.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RongCloudChat;
