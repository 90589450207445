/* eslint-disable no-await-in-loop */
import { request } from 'graphql-request';
import merge from 'lodash/merge';
import {
  CommonLocalizableString,
  CommonLocalizableImage,
  CommonLocalizableVideo,
  CommonLocalizableFile,
  CommonBoothTier,
  NonQuestionContentElement,
} from './common/cmsFragment';
import { apiUrl } from './common/cmsConfig';

const fetchBoothMetaByIdQuery = `
  ${CommonLocalizableString}
  ${CommonLocalizableImage}
  ${CommonBoothTier}

  query fetchBoothMetaByIdQuery($boothId: ID!, $locales: [Locale!]!) {
    node(id: $boothId) {
      ... on Booth {
        id
        appliedDesignCodename
        appointmentBookingUrl
        meetupEnabled
        website
        boothNumber
        description {
          ...CommonLocalizableString
        }
        name {
          ...CommonLocalizableString
        }
        logo {
          ...CommonLocalizableImage
        }
        tier {
          ...CommonBoothTier
        }
        categories {
          nodes {
            id
            name {
              ...CommonLocalizableString
            }
          }
        }
      }
    }
  }
`;

const fetchBoothDesignElementsByIdQuery = `
  ${CommonLocalizableString}
  ${CommonLocalizableImage}
  ${CommonLocalizableVideo}
  ${CommonLocalizableFile}

  query fetchBoothDesignElementsByIdQuery($boothId: ID!, $locales: [Locale!]!) {
    node(id: $boothId) {
      ... on Booth {
        id
        designElements {
          nodes {
            id
            name
            kind
            color
            image {
              ...CommonLocalizableImage
            }
            video {
              ...CommonLocalizableVideo
            }
            contentRichtextHtml {
              ...CommonLocalizableString
            }
            thumbnailImage {
              ...CommonLocalizableImage
            }
            pdf {
              ...CommonLocalizableFile
            }
          }
        }
      }
    }
  }
`;

const fetchBoothRootContentElementByIdQuery = `
  ${CommonLocalizableString}
  ${CommonLocalizableImage}
  ${CommonLocalizableVideo}
  ${CommonLocalizableFile}
  ${NonQuestionContentElement}

  query fetchBoothRootContentElementByIdQuery($boothId: ID!, $locales: [Locale!]!, $tabCount: Int, $tabCursor: String) {
    node(id: $boothId) {
      ... on Booth {
        id
        rootContentElement {
          tabs(first: $tabCount, after: $tabCursor) {
            pageInfo {
              hasNextPage
              endCursor
            }
            nodes {
              title {
                ...CommonLocalizableString
              }
              body {
                nodes {
                  __typename
                  id
                  ...NonQuestionContentElement
                  ... on BoothQuestionContentElement {
                    question {
                      ...CommonLocalizableString
                    }
                    answer {
                      ...NonQuestionContentElement
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const boothById = async (boothId: string, locales = ['en'], needMeta = true) => {
  const config = { boothId, locales };
  const rootContentElementConfig = { ...config, tabCount: 5 };

  const tasks = [
    needMeta
      ? request(apiUrl, fetchBoothMetaByIdQuery, config).then((it) => it.node)
      : Promise.resolve({}),
    request(apiUrl, fetchBoothDesignElementsByIdQuery, config).then((it) => it.node),
    request(apiUrl, fetchBoothRootContentElementByIdQuery, rootContentElementConfig).then((it) => it.node),
  ];

  const promises = await Promise.all(tasks);

  let tabPageInfo = promises[2].rootContentElement.tabs.pageInfo;
  delete promises[2].rootContentElement.tabs.pageInfo;

  const result: any = promises.reduce((sum, next) => merge(sum, next), {});

  while (tabPageInfo?.hasNextPage) {
    const node = await request(apiUrl, fetchBoothRootContentElementByIdQuery, {
      ...rootContentElementConfig,
      tabCursor: tabPageInfo.endCursor,
    }).then((it) => it.node);
    tabPageInfo = node.rootContentElement.tabs.pageInfo;
    result.rootContentElement.tabs.nodes = [
      ...result.rootContentElement.tabs.nodes,
      ...node.rootContentElement.tabs.nodes,
    ];
  }

  return result;
};

export default boothById;
