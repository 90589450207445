/* eslint-disable no-underscore-dangle */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import remoteTimeNow from 'libs/remote-time-now';
import { selectAvailableLocales } from 'models/localization';
import boothById from 'network/boothById';
import moment from 'moment';
import { normalize } from 'normalizr';
import {
  boothsAdapter,
  selectBoothPageInfo, selectBoothById,
} from './selectors';
import {
  fetchPreviewBoothsRequest, fetchMetaBoothsRequest, fetchBoothCategoriesByIdRequest, fetchBoothDesignElementsByIdRequest, fetchBoothCategoriesByIdsRequest,
} from './requests';
import {
  Booth, BoothFetchedLevel, BoothFetchingState,
  isFetchingLevelLowerThanExpected,
  boothEntity,
} from './types';
import { fetchCachedCMSData } from '../common/thunks';

export const backgroundFetchBoothById = createAsyncThunk(
  'booths/backgroundFetchBoothById',
  async (boothId: string, { getState }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);
      const response = await boothById(boothId, locales, false);

      response.$fetchedLevel = BoothFetchedLevel.Details;
      response.$lastReceivedAt = remoteTimeNow().toISOString();

      const { entities } = normalize<Booth>([response], [boothEntity]);
      return entities.booths || {};
    } catch (error) {
      console.error(error);
    }
    return {};
  },
  {
    condition(boothId: string, { getState }) {
      const state = getState() as any;
      const fetchingState = state.cms.booths.boothFetchingState[boothId];
      switch (fetchingState) {
        case BoothFetchingState.Rejected: return false;
        case BoothFetchingState.Pending: return false;
        case BoothFetchingState.Fulfilled:
        default: return true;
      }
    },
  },
);


export interface UpdateBoothByIdProps {
  boothId: string;
  expectedLevel?: BoothFetchedLevel;
}

export const updateBoothById = createAsyncThunk(
  'booths/updateBoothById',
  async ({ boothId, expectedLevel }: UpdateBoothByIdProps, { getState }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);

      const handler = (() => {
        switch (expectedLevel) {
          case BoothFetchedLevel.Preview:
            return () => fetchBoothDesignElementsByIdRequest({ boothId, locales });
          default:
            return () => boothById(boothId, locales);
        }
      })();

      const response = await handler();
      response.$fetchedLevel = expectedLevel;
      response.$lastReceivedAt = remoteTimeNow().toISOString();

      const { entities } = normalize<Booth>([response], [boothEntity]);
      return entities.booths || {};
    } catch (error) {
      console.error(error);
    }
    return {};
  },
  {
    condition({ boothId, expectedLevel }, { getState }) {
      const state = getState() as any;
      const fetchingState = state.cms.booths.boothFetchingState[boothId];
      switch (fetchingState) {
        case BoothFetchingState.Rejected: return false;
        case BoothFetchingState.Pending: return false;
        case BoothFetchingState.Fulfilled: {
          const booth = selectBoothById(state, boothId);
          if (isFetchingLevelLowerThanExpected(booth.$fetchedLevel, expectedLevel)) return true;
          return remoteTimeNow().diff(moment(booth.$lastReceivedAt), 'minutes') > 4.5;
        }
        default: return true;
      }
    },
  },
);

export const fetchBoothById = createAsyncThunk(
  'booths/fetchBoothById',
  async (boothId: string, { getState }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);
      const response = await boothById(boothId, locales);

      response.$fetchedLevel = BoothFetchedLevel.Details;
      response.$lastReceivedAt = remoteTimeNow().toISOString();

      const { entities } = normalize<Booth>([response], [boothEntity]);
      return entities.booths || {};
    } catch (error) {
      console.error(error);
    }
    return {};
  },
  {
    condition(boothId: string, { getState }) {
      const state = getState() as any;
      const fetchingState = state.cms.booths.boothFetchingState[boothId];
      switch (fetchingState) {
        case BoothFetchingState.Rejected: return false;
        case BoothFetchingState.Pending: return false;
        case BoothFetchingState.Fulfilled: {
          const booth = selectBoothById(state, boothId);
          if (isFetchingLevelLowerThanExpected(booth.$fetchedLevel, BoothFetchedLevel.Details)) return true;
          return remoteTimeNow().diff(moment(booth.$lastReceivedAt), 'minutes') > 4.5;
        }
        default: return true;
      }
    },
  },
);

export const fetchPreviewBooths = createAsyncThunk(
  'booths/fetchPreviewBooths',
  async ({ boothCount, boothsCursor, continueFetching = false }: any = {}, { getState, dispatch }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);
      const response = await fetchPreviewBoothsRequest({ locales, boothsCursor, boothCount });

      const { pageInfo } = response.booths;
      delete response.booths.pageInfo;
      if (continueFetching && pageInfo.hasNextPage) dispatch(fetchPreviewBooths({ boothsCursor: pageInfo.endCursor, continueFetching, boothCount }));

      const now = remoteTimeNow().toISOString();
      response.booths.nodes = response.booths.nodes.map((node) => ({
        ...node,
        $fetchedLevel: BoothFetchedLevel.Preview,
        $lastReceivedAt: now,
      }));

      const { entities } = normalize<Booth>(response.booths.nodes, [boothEntity]);

      return {
        booths: entities.booths || {},
        pageInfo: {
          ...pageInfo,
          fetching: continueFetching && pageInfo.hasNextPage,
        },
      };
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

const fetchBoothCategoriesById = createAsyncThunk(
  'booths/fetchBoothCategoriesById',
  async ({ boothId, cursor }: any, { getState, dispatch }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);
      const response = await fetchBoothCategoriesByIdRequest({
        boothId, locales, cursor,
      });

      const { pageInfo } = response.categories;
      delete response.categories.pageInfo;
      if (pageInfo.hasNextPage) dispatch(fetchBoothCategoriesById({ boothId, cursor: pageInfo.endCursor }));

      const { entities } = normalize<Booth>(response, boothEntity);
      return entities.booths || {};
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

const fetchBoothCategoriesByIds = createAsyncThunk(
  'booths/fetchBoothCategoriesByIds',
  async ({ boothIds }: any, { getState, dispatch }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);
      const response = await fetchBoothCategoriesByIdsRequest({
        boothIds, locales,
      });

      response.forEach((it: any) => {
        if (!it.categories.pageInfo?.hasNextPage) return;
        dispatch(fetchBoothCategoriesById({ boothId: it.id, cursor: it.categories.pageInfo.endCursor }));
      });

      const mapped = response.map((it: any) => ({ id: it.id, categories: { nodes: it.categories.nodes } }));

      const { entities } = normalize<Booth>(mapped, [boothEntity]);
      return entities.booths || {};
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const fetchMetaBooths = createAsyncThunk(
  'booths/fetchMetaBooths',
  async ({ boothCount, boothsCursor, continueFetching = false }: any = {}, { getState, dispatch }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);
      const response = await fetchMetaBoothsRequest({ locales, boothsCursor, boothCount });

      const { pageInfo } = response.booths;
      delete response.booths.pageInfo;
      if (continueFetching && pageInfo.hasNextPage) dispatch(fetchMetaBooths({ boothsCursor: pageInfo.endCursor, continueFetching, boothCount }));

      const now = remoteTimeNow().toISOString();
      response.booths.nodes = response.booths.nodes.map((node) => ({
        ...node,
        $fetchedLevel: BoothFetchedLevel.Meta,
        $lastReceivedAt: now,
      }));

      const { entities } = normalize<Booth>(response.booths.nodes, [boothEntity]);

      dispatch(fetchBoothCategoriesByIds({
        boothIds: response.booths.nodes.map((it) => it.id),
        locales,
      }));

      return {
        booths: entities.booths || {},
        pageInfo: {
          ...pageInfo,
          fetching: continueFetching && pageInfo.hasNextPage,
        },
      };
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const fetchMoreMetaBooths = createAsyncThunk(
  'booths/fetchMoreMetaBooths',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const pageInfo = selectBoothPageInfo(state);
    return dispatch(fetchMetaBooths({
      boothsCursor: pageInfo.endCursor,
    }));
  },
  {
    condition(_, { getState }) {
      const state = getState();
      const pageInfo = selectBoothPageInfo(state);
      return pageInfo.hasNextPage && !pageInfo.fetching;
    },
  },
);

export const boothsSlice = createSlice({
  name: 'booths',
  initialState: boothsAdapter.getInitialState({
    fetching: true,
    pageInfo: {
      hasNextPage: true,
      endCursor: null,
      fetching: false,
    },
    boothFetchingState: {},
  }),
  reducers: {
    setPageInfoFetching(state, action: PayloadAction<boolean>) {
      state.pageInfo.fetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCachedCMSData.fulfilled, (state, action) => {
      boothsAdapter.upsertMany(state, action.payload.booths);
      state.ids = action.payload.boothIds;
      state.fetching = false;
      state.pageInfo = {
        hasNextPage: false,
        endCursor: null,
        fetching: false,
      };
    });

    builder.addCase(fetchMetaBooths.pending, (state) => {
      state.pageInfo.fetching = true;
    });
    builder.addCase(fetchMetaBooths.rejected, (state) => {
      state.pageInfo.fetching = false;
    });
    builder.addCase(fetchMetaBooths.fulfilled, (state, action) => {
      if (Object.keys(action.payload).length === 0) {
        state.fetching = false;
        state.pageInfo.hasNextPage = false;
        state.pageInfo.fetching = false;
        return;
      }
      state.fetching = false;
      state.pageInfo = action.payload.pageInfo;
      boothsAdapter.upsertMany(state, action.payload.booths || {});
    });

    builder.addCase(fetchPreviewBooths.pending, (state) => {
      state.pageInfo.fetching = true;
    });
    builder.addCase(fetchPreviewBooths.rejected, (state) => {
      state.pageInfo.fetching = false;
    });
    builder.addCase(fetchPreviewBooths.fulfilled, (state, action) => {
      if (Object.keys(action.payload).length === 0) {
        state.fetching = false;
        state.pageInfo.hasNextPage = false;
        state.pageInfo.fetching = false;
        return;
      }
      state.fetching = false;
      state.pageInfo = action.payload.pageInfo;
      if (action.payload.booths) {
        const filtered = Object.fromEntries(
          Object.entries(action.payload.booths)
            .filter(([key]) => state.entities[key]?.$fetchedLevel !== BoothFetchedLevel.Details),
        );
        boothsAdapter.upsertMany(state, filtered);
      }
    });

    builder.addCase(fetchBoothById.pending, (state, action) => {
      const boothId = action?.meta?.arg;
      state.boothFetchingState[boothId] = BoothFetchingState.Pending;
    });
    builder.addCase(fetchBoothById.rejected, (state, action) => {
      const boothId = action?.meta?.arg;
      state.boothFetchingState[boothId] = BoothFetchingState.Rejected;
    });
    builder.addCase(fetchBoothById.fulfilled, (state, action) => {
      const boothId = action?.meta?.arg;
      state.boothFetchingState[boothId] = BoothFetchingState.Fulfilled;
      boothsAdapter.upsertMany(state, action.payload);
    });

    builder.addCase(backgroundFetchBoothById.fulfilled, (state, action) => {
      boothsAdapter.upsertMany(state, action.payload);
    });

    builder.addCase(fetchBoothCategoriesById.fulfilled, (state, action) => {
      Object.entries(action.payload)
        .forEach(([key, value]) => {
          if (!state.entities[key].categories) state.entities[key].categories = [];
          state.entities[key].categories.push(...value.categories);
        });
    });

    builder.addCase(fetchBoothCategoriesByIds.fulfilled, (state, action) => {
      boothsAdapter.upsertMany(state, action.payload);
    });

    builder.addCase(updateBoothById.pending, (state, action) => {
      const boothId = action?.meta?.arg?.boothId;
      state.boothFetchingState[boothId] = BoothFetchingState.Pending;
    });
    builder.addCase(updateBoothById.rejected, (state, action) => {
      const boothId = action?.meta?.arg?.boothId;
      state.boothFetchingState[boothId] = BoothFetchingState.Rejected;
    });
    builder.addCase(updateBoothById.fulfilled, (state, action) => {
      const boothId = action?.meta?.arg?.boothId;
      state.boothFetchingState[boothId] = BoothFetchingState.Fulfilled;
      boothsAdapter.upsertMany(state, action.payload);
    });
  },
});

export default boothsSlice.reducer;
