export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/5d35d8bb-3baf-4c16-8351-5eac5f52a611.png',
  ],
  url: {
    exhibition_website: 'https://www.japan-mfg-kansai.jp/en-gb.html',
  },
  title: 'Manufacturing World Osaka 2020 -VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a22ac2d3-54dd-4a31-a756-5f5e1618f7e9.jpg',
  ],
  url: {
    exhibition_website: 'https://www.japan-mfg-kansai.jp/',
  },
  title: 'オンライン来場・商談サービス｜第23回 [関西] ものづくり ワールド',
};
