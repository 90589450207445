/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchCachedCMSDataRequest from 'network/fetchCachedCMSData';
import { normalize } from 'normalizr';
import { eventToken } from 'network/common/cmsConfig';
import { CMSEvent, eventEntity } from '../events/types';
import { boothEntity, Booth, BoothFetchingState } from '../booths/types';

export const fetchCachedCMSData = createAsyncThunk(
  'cms/fetchCachedCMSData',
  async () => {
    const { data: { eventByToken: data } } = await fetchCachedCMSDataRequest();

    const { halls } = data;
    delete data.halls;

    if (!data.id) {
      data.id = eventToken;
    }

    const boothsNodes = halls.nodes.flatMap((it: any) => it.booths.nodes);
    const boothIds = boothsNodes.map((it: any) => it.id);
    const boothFetchingState = boothIds
      .reduce((sum: any, it: any) => ({
        ...sum,
        [it]: BoothFetchingState.Fulfilled,
      }), {});

    const { events } = normalize<CMSEvent>(data, eventEntity).entities;
    const { booths } = normalize<Booth>(boothsNodes, [boothEntity]).entities;

    return {
      events,
      booths,
      boothIds,
      boothFetchingState,
    };
  },
);
