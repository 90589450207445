/* eslint-disable no-underscore-dangle */
import { schema } from 'normalizr';
import {
  LocalizableString, LocalizableImage,
  LocalizableVideo, LocalizableFile, WithTypename,
} from '../common/types';
import { BoothTaxonomies } from '../events';
import { mapLocalizable } from '../common/utils';

export enum DesignElementKind {
  Image = 'IMAGE',
  Richtext = 'RICHTEXT',
  Color = 'COLOR',
  Video = 'VIDEO',
  PDF = 'PDF',
}

export interface DesignElement {
  id: string;
  name: string;
  kind: DesignElementKind;
  color?: string;
  image?: LocalizableImage;
  video?: LocalizableVideo;
  contentRichtextHtml?: LocalizableString;
  thumbnailImage?: LocalizableImage;
  pdf?: LocalizableFile;
}

export type BoothContentElement = WithTypename<{
  id: string;
  contentRichtextHtml?: LocalizableString;
  image?: LocalizableImage;
  videoFile?: LocalizableVideo;
  file?: LocalizableFile;
  question?: LocalizableString;
  answer?: BoothContentElement;
}>;

export enum BoothFetchingState {
  Pending = 'PENDING',
  Fulfilled = 'FULFILLED',
  Rejected = 'REJECTED',
}

export enum BoothFetchedLevel {
  Meta = 'META',
  Preview = 'PREVIEW',
  Details = 'DETAILS',
}

export const BoothFetchedLevelOrder = [
  BoothFetchedLevel.Meta,
  BoothFetchedLevel.Preview,
  BoothFetchedLevel.Details,
];

export const isFetchingLevelLowerThanExpected = (currentLevel: BoothFetchedLevel, expectedLevel: BoothFetchedLevel) => BoothFetchedLevelOrder.indexOf(currentLevel) < BoothFetchedLevelOrder.indexOf(expectedLevel);

export interface Booth {
  salesImg: any;
  id: string;
  appliedDesignCodename: string;
  appointmentBookingUrl: string;
  meetupEnabled: boolean;
  website: string;
  boothNumber: string;
  description: LocalizableString;
  name: LocalizableString;
  logo: LocalizableImage;
  tier: WithTypename<{ name: LocalizableString }>;
  designElements: DesignElement[];
  categories: BoothTaxonomies[];
  rootContentElement: {
    tabs: {
      title: LocalizableString;
      body: BoothContentElement[];
    }[];
  };
  hasAttachment: boolean;
  attachmentTab: number;
  livestreams: any;
  videoCallUrl: string;

  $fetchedLevel: BoothFetchedLevel;
  $lastReceivedAt: string;
}


const urlRegex = /<p>(.*)<\/p>/;

export const boothEntity = new schema.Entity('booths', {}, {
  processStrategy(entity) {
    let livestreams: any;
    const tabs = [];
    entity?.rootContentElement?.tabs?.nodes
      .forEach((it, index) => {
        if (it.title.values[0].value === 'Live Stream(s)') {
          if (it.body.nodes[0].answer.contentRichtextHtml.values[0].value) {
            livestreams = [{
              index,
            }];
            tabs.push(it);
          }
        } else if (it.title.values[0].value === 'Video Call') {
          if (it.body.nodes[0].answer.contentRichtextHtml.values[0].value) {
            [, entity.videoCallUrl] = it.body.nodes[0].answer.contentRichtextHtml.values[0].value.match(urlRegex);
          }
        } else {
          tabs.push(it);
        }
      });
    if (entity?.rootContentElement?.tabs?.nodes) {
      entity.rootContentElement.tabs.nodes = tabs;
    }
    entity.livestreams = livestreams;

    let attachmentTab = -1;
    const hasAttachment = entity?.rootContentElement?.tabs?.nodes
      ?.some(({ body: { nodes } }, index) => (
        nodes.some((element) => {
          attachmentTab = index;
          return element.__typename === 'BoothFileContentElement';
        })
      ));
    entity.attachmentTab = attachmentTab;
    entity.hasAttachment = !!hasAttachment;

    return mapLocalizable(entity);
  },
});
