export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/4efcbdd2-066c-40be-a55c-0245464d1645.png',
  ],
  url: {
    exhibition_website: 'https://www.agriexpo-tokyo.jp/en-gb.html',
  },
  title: 'AGRI WEEK TOKYO -VIRTURAL- GARDEX -VIRTURAL- TOOL JAPAN -VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/90baa5c5-0fec-4a1a-854f-9fe48609da22.png',
  ],
  url: {
    exhibition_website: 'https://www.agriexpo-tokyo.jp/ja-jp.html',
  },
  title: 'オンライン来場・商談サービス｜【農業Week】【ガーデンEXPO】【ツールジャパン】',
};
