const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

// eslint-disable-next-line import/prefer-default-export
export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/3bcc1843-e1cd-42b8-a326-fb9add21611e.png',
        '/booth/fXNV9TQB86TTAeveruDZHzSegn2DyMjJfiCRxpU6mtMo',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/6f430c2a-02c0-4fcd-b21e-feff525bfae4.png',
        '/booth/85GyjXGcYDQkfwTMgXDH67hsjskMLZntqiH8jWps5u8x',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ef4918c0-53ef-415d-9adb-ebf2bbb614a8.png',
        '/booth/gUBeJRfxbhc9BgpWDLJCXHK54tkVYdWiTX2QT3GZQc2X',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/6f430c2a-02c0-4fcd-b21e-feff525bfae4.png',
        '/booth/85GyjXGcYDQkfwTMgXDH67hsjskMLZntqiH8jWps5u8x',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ef4918c0-53ef-415d-9adb-ebf2bbb614a8.png',
        '/booth/gUBeJRfxbhc9BgpWDLJCXHK54tkVYdWiTX2QT3GZQc2X',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/3bcc1843-e1cd-42b8-a326-fb9add21611e.png',
        '/booth/fXNV9TQB86TTAeveruDZHzSegn2DyMjJfiCRxpU6mtMo',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/df0ac4f2-601c-4ac4-994a-0ccdb27c2214.jpg',
        'https://www.office-expo.jp/ja-jp.html',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
  ...ja.banners.left,
  ...ja.banners.right,
];
